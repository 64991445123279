export enum Route {
    AIPrompts = 'ai-prompts',
    AIResults = 'ai-results',
    Article = 'artikel',
    Articles = 'articles/[[...status]]',
    AuditLogs = 'audit-logs',
    BlockedIps = 'blocked-ips',
    Comments = 'comments/[[...tab]]',
    Experiments = 'experiments',
    FilteredComments = 'filtered-comments',
    ForbiddenWords = 'forbidden-words',
    ManageTags = 'tags/manage',
    MatchReports = 'match-reports',
    Media = 'media',
    ModuleSchedule = 'module-schedule/[[...tab]]',
    OptaExport = 'opta-exports',
    Pages = 'pages',
    TierGroups = 'tier-groups',
    Redirects = 'redirects',
    Tags = 'tags/[[...tagType]]',
    User = 'gebruiker',
    Users = 'users',
    Videofeed = 'media/videofeed/[[...status]]',
    DeprecatedPaginas = 'paginas',
}
